import React, { useRef, useEffect } from "react";

const SlidingGallery = () => {
  const images = Array.from({ length: 30 }, (_, i) => `/assets/slider/${i + 1}.png`);
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      let scrollPosition = 0;

      const scrollContent = () => {
        scrollPosition += 1; // Increment scroll position
        container.scrollLeft = scrollPosition;

        // Reset to the start for seamless scrolling
        if (scrollPosition >= container.scrollWidth / 2) {
          scrollPosition = 0;
        }

        requestAnimationFrame(scrollContent); // Use requestAnimationFrame for smooth scrolling
      };

      scrollContent(); // Start scrolling

      return () => cancelAnimationFrame(scrollContent); // Cleanup on unmount
    }
  }, []);

  return (
    <div
      ref={containerRef}
      className="overflow-hidden whitespace-nowrap flex items-center"
      style={{
        display: "flex",
        gap: "10px",
        width: "100%",
        overflow: "hidden", // Ensures content stays inside the container
        padding: "20px 0",
      }}
    >
      {/* Duplicate images to create seamless loop */}
      {images.concat(images).map((src, index) => (
        <img
          key={index}
          src={src}
          alt={`NFT ${index + 1}`}
          className="inline-block w-40 h-40 mx-2 rounded-md shadow-md transform hover:scale-105 transition-transform"
        />
      ))}
    </div>
  );
};

export default SlidingGallery;
