import React from "react";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <div className="text-center py-20 px-8 md:px-20">
      <h1 className="text-5xl font-bold text-primaryText">
        Welcome to Doodle Dawgs
      </h1>
      <p className="text-lg text-primaryText mt-4">
        Unleash that Dawg in you with <span className="font-semibold">Doodle Dawgs</span>—a collection of hand-drawn, personality-packed NFTs that embody creativity and individuality. These vibrant canines aren’t just art; they’re a symbol of self-expression and fun.
      </p>
      <Link to="/gallery">
        <button className="mt-6 px-6 py-2 bg-pastelGreen text-primaryText font-semibold rounded-lg hover:bg-pastelYellow">
          Explore the Collection
        </button>
      </Link>
    </div>
  );
};

export default HeroSection;
