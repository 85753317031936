const Footer = () => {
  return (
    <footer className="text-primaryText text-center py-4">
      <p className="text-sm">
        &copy; 2024-2025 Doodle Dawgs. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
