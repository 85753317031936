import React, { useState, useEffect, useRef } from "react";
import { FaDownload, FaCopy, FaCheck, FaDice } from "react-icons/fa";

const layersOrder = ["Background", "Fur", "Clothing", "Mouth", "Head", "Eye Accessory"];

const defaultTraits = {
  Background: "Yellow.png",
  Fur: "Gold.png",
  Clothing: "Naked.png",
  Mouth: "None.png",
  Head: "King Crown.png",
  "Eye Accessory": "None.png",
};

const Gallery = () => {
  const [layerOptions, setLayerOptions] = useState({});
  const [selectedTraits, setSelectedTraits] = useState(defaultTraits);
  const [copied, setCopied] = useState(false);
  const canvasRef = useRef(null);

  useEffect(() => {
    const fetchTraits = async () => {
      try {
        const response = await fetch("/traits.json");
        const data = await response.json();
        setLayerOptions(data.layers);
        updateCanvas(defaultTraits);
      } catch (error) {
        console.error("Error loading traits.json:", error);
      }
    };

    fetchTraits();
  }, []);

  const updateCanvas = async (traits) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    canvas.width = 400;
    canvas.height = 400;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    for (let layer of layersOrder) {
      const trait = traits[layer];
      if (!trait) continue;

      const image = new Image();
      image.src = `/layers/${layer}/${trait}`;
      await new Promise((resolve) => {
        image.onload = () => {
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          resolve();
        };
      });
    }
  };

  const handleTraitChange = (layer, value) => {
    const updatedTraits = { ...selectedTraits, [layer]: value };
    setSelectedTraits(updatedTraits);
    updateCanvas(updatedTraits);
  };

const randomizeTraits = () => {
  const randomizedTraits = {};
  layersOrder.forEach((layer) => {
    let options = layerOptions[layer];
    // Filter out "None" for the Background layer
    if (layer === "Background" && options) {
      options = options.filter((option) => option !== "None.png");
    }
    if (options && options.length > 0) {
      randomizedTraits[layer] = options[Math.floor(Math.random() * options.length)];
    }
  });
  setSelectedTraits(randomizedTraits);
  updateCanvas(randomizedTraits);
};


  const downloadImage = () => {
    const canvas = canvasRef.current;
    const link = document.createElement("a");
    link.download = "generated-dawg.png";
    link.href = canvas.toDataURL("image/png");
    link.click();
  };

  const copyToClipboard = () => {
    const canvas = canvasRef.current;
    canvas.toBlob((blob) => {
      const item = new ClipboardItem({ "image/png": blob });
      navigator.clipboard.write([item]);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="gallery-container text-center p-4">
      <h1
        className="text-4xl font-bold mb-4"
        style={{
          fontFamily: "'Comic Neue', cursive",
          color: "#ff6f91", // Fun, vibrant color
        }}
      >
        Dawg Designer
      </h1>
      <div
        className="container mx-auto flex flex-col md:flex-row items-center justify-center gap-8 p-8"
      
      >
        {/* Left Section: Dropdowns */}
        <div className="flex flex-col items-start gap-4">
          {layersOrder.map((layer) => (
            <div key={layer} className="flex flex-col items-start">
              <label
                className="font-semibold mb-1"
                style={{
                  fontFamily: "'Comic Neue', cursive",
                  color: "#ff6f91", // Matching the title's font color
                }}
              >
                {layer}
              </label>
              <select
                className="border rounded px-4 py-2 text-center w-40 bg-transparent"
                style={{
                  color: "#333",
                  fontFamily: "'Comic Neue', cursive",
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  borderColor: "#ffb3ba", // Match the container border
                }}
                value={selectedTraits[layer] || ""}
                onChange={(e) => handleTraitChange(layer, e.target.value)}
                disabled={!layerOptions[layer]}
              >
                {layerOptions[layer]
                  ? layerOptions[layer].map((option) => (
                      <option key={option} value={option}>
                        {option.replace(".png", "")}
                      </option>
                    ))
                  : <option>Loading...</option>}
              </select>
            </div>
          ))}
        </div>

        {/* Right Section: Canvas */}
        <div className="flex flex-col items-center">
          {/* Buttons above the canvas */}
          <div className="flex justify-center items-center gap-4 mb-4">
            <button
              onClick={randomizeTraits}
              className="p-3 rounded-full text-white font-semibold"
              style={{
                backgroundColor: "#ff6f91",
                fontFamily: "'Comic Neue', cursive",
              }}
            >
              <FaDice />
            </button>
            <button
              onClick={copyToClipboard}
              className="p-3 rounded-full text-white font-semibold"
              style={{
                backgroundColor: "#ff6f91",
                fontFamily: "'Comic Neue', cursive",
              }}
            >
              {copied ? <FaCheck /> : <FaCopy />}
            </button>
            <button
              onClick={downloadImage}
              className="p-3 rounded-full text-white font-semibold"
              style={{
                backgroundColor: "#ff6f91",
                fontFamily: "'Comic Neue', cursive",
              }}
            >
              <FaDownload />
            </button>
          </div>

          {/* Canvas */}
          <canvas
            ref={canvasRef}
            className="border mx-auto"
            width={400}
            height={400}
            style={{
              display: "block",
              border: "4px solid #ffb3ba", // Matching the container border
              borderRadius: "10px",
            }}
          ></canvas>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
