import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faDiscord } from "@fortawesome/free-brands-svg-icons";

const Navbar = () => {
  const [logoSrc, setLogoSrc] = useState("/assets/logo.png");

  return (
    <nav className="flex justify-end items-center px-6 py-4">
      <div className="flex items-center space-x-4">
        <a
          href="/"
          className="flex items-center"
          onMouseEnter={() => setLogoSrc("/assets/logoyellow.png")}
          onMouseLeave={() => setLogoSrc("/assets/logo.png")}
        >
          <img
            src={logoSrc}
            alt="Logo"
            className="h-8 w-auto align-middle"
          />
        </a>
        <a
          href="https://x.com/dooodledawg"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: "inline-block",
            color: "#C8A2C8",
          }}
          onMouseEnter={(e) => (e.currentTarget.style.color = "#FFF6B7")} // pastelYellow
          onMouseLeave={(e) => (e.currentTarget.style.color = "#C8A2C8")}
        >
          <FontAwesomeIcon
            icon={faTwitter}
            className="h-8 w-8 relative"
            style={{
              color: "inherit",
              top: "4px", // Moves the icon down by 4px
            }}
          />
        </a>
        <a
          href="https://discord.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: "inline-block",
            color: "#C8A2C8",
          }}
          onMouseEnter={(e) => (e.currentTarget.style.color = "#FFF6B7")} // pastelYellow
          onMouseLeave={(e) => (e.currentTarget.style.color = "#C8A2C8")}
        >
          <FontAwesomeIcon
            icon={faDiscord}
            className="h-8 w-8 relative"
            style={{
              color: "inherit",
              top: "4px", // Moves the icon down by 4px
            }}
          />
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
